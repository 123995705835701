import { INST_TYPE_INSURANCE } from '@constant/enum'

import { validatePhoneNumber } from '@weights/CreateForm/validate/validates'

const REQUIRED_VALIATOR = {
    required: true,
    message: '此项不能为空'
}

export const FORM_LAYOUT = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 14
    }
}

export const FORM_COLUMNS = [
    [
        {
            fieldCode: 'policyHolderName',
            label: '投保人',
            component: 'AInput',
            extraProps: {
                disabled: true
            }
        },
        {
            fieldCode: 'projectName',
            label: '工程名称',
            component: 'AInput',
            extraProps: {
                disabled: true
            }
        }
    ],

    [
        {
            fieldCode: 'contact',
            label: '经办联系人',
            component: 'AInput',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                placeholder: '请输入内容'
            }
        },
        {
            fieldCode: 'mobile',
            label: '经办人手机',
            component: 'AInput',
            rules: [
                REQUIRED_VALIATOR,
                {
                    validator: validatePhoneNumber
                }
            ],
            extraProps: {
                placeholder: '请输入内容'
            }
        }
    ],

    [
        {
            fieldCode: 'brandName',
            label: '保险品牌',
            component: 'FinancialInstitutionBrandSelect',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                optionValue: 'superBrandName',
                placeholder: '请输入内容',
                institutionType: INST_TYPE_INSURANCE
            }
        },
        {
            fieldCode: 'organizationName',
            label: '承保出单机构',
            component: 'AInput',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                placeholder: '请输入内容'
            }
        }
    ],

    [
        {
            fieldCode: 'businessName',
            label: '产品名称',
            component: 'AInput',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                disabled: true
            }
        },
        {
            fieldCode: 'policyNo',
            label: '保单号',
            component: 'AInput',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                placeholder: '请输入内容'
            }
        }
    ],

    [
        {
            fieldCode: 'guaranteeAmount',
            label: '保额',
            component: 'AInputNumber',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                precision: 2,
                placeholder: '请输入内容',
                style: 'width: 100%'
            }
        },
        {
            fieldCode: 'paymentAmount',
            label: '保费',
            component: 'AInputNumber',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                precision: 2,
                placeholder: '请输入内容',
                style: 'width: 100%'
            }
        }
    ],

    [
        {
            fieldCode: 'deliveryTime',
            label: '出单日期',
            component: 'ADatePicker',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                format: 'YYYY-MM-DD',
                valueFormat: 'YYYY-MM-DD',
                style: 'width: 100%'
            }
        },
        {
            fieldCode: 'guaranteeStartDateAndEndDate',
            label: '保险期限',
            component: 'CustomDateRangePicker',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                style: 'width: 100%'
            }
        }
    ],

    [
        {
            fieldCode: 'policyUrl',
            label: '保单文件',
            component: 'CustomUploadFile',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                fileNumber: 1
            }
        }
    ]
]
